* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}



body {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    color: #333;
    /* background: linear-gradient(180deg, #27242F 0%, #1D1929 100%), #000000; */
}



@keyframes moveToEnd {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(200%);
    }
}

.moveToEnd {
    animation: moveToEnd .5s linear;
    animation-fill-mode: forwards;
}



@keyframes moveBox {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(250px);
    }

    51% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(500px);
    }
}

@keyframes moveTypography {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-50px);
    }

    51% {
        transform: translateY(0);
    }
}

.moveToMiddle {
    animation: moveBox 4s linear;
    animation-fill-mode: forwards;
}

.moveTypography {
    animation: moveTypography 4s linear;
}


.nonSelect * {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -o-user-select: none;
    user-select: none;
}

::-webkit-scrollbar {
    display: none;
}

.flip-icon {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.buyToast, .sellToast, .winToast, .rejectedToast  { 
    gap: 8px;
    display: flex;
    flex-direction: row; 
    align-items: center;
}
.buyToast {background: #7A6FF8 !important;}
.sellToast {background: #0AAD36 !important;}
.winToast {background: #22E64B !important;}
.rejectedToast {background: #FF6768 !important;}

.notistack-SnackbarContainer { position:absolute !important;top:100px !important; }
